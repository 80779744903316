import Image, { ImageProps } from 'next/image'
import React, { useEffect, useState } from 'react'

interface ImgProps {
    /**
     * the webp url if exist, if not pass the normal url
     */
    src: string
    /**
     * normal url to fallback when src (.webp) failed to load
     */
    fallbackSrc?: string
    /**
     * disable skeleton effect on image loading
     */
    withoutSkeletonEffect?: boolean
}



const Img = (props: ImgProps & ImageProps) => {
    const [imgSrc, setImgSrc] = useState(props.src)

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setImgSrc(props.src)
    }, [props.src])

    return (
        <Image
            layout={props.width ? undefined : 'fill'}
            {...props}
            className={`${props.className} ${(isLoading && !props.withoutSkeletonEffect) ? 'LazyLoad' : ''}`}
            src={imgSrc}
            onLoadingComplete={(result) => {
                if (result.naturalWidth === 0) {
                    if (props.fallbackSrc && imgSrc !== props.fallbackSrc) setImgSrc(props.fallbackSrc)
                } else {
                    setIsLoading(false)
                }
            }}
            onError={() => {
                if (props.fallbackSrc) setImgSrc(props.fallbackSrc)
            }}
        />
    )
}

export default Img
