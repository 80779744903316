import React from 'react'
import Img from '../../Img'
import Button from '../../forms/Button'
import SearchIconThin from '../../icons/SearchIconThin'

type Props = {
    lang: any
    langCode: string
    onClickSearch: () => void
}

const FindYourDreamApartment = ({ lang, langCode, onClickSearch }: Props) => {
    return (
        <div className='w-full relative lg:pt-0 pt-8'>
            <div className='relative hidden lg:flex rounded-xl overflow-hidden bg-gray-eb' style={{ height: 280 }}>
                <div style={{ left: '30%' }} className='absolute z-5 right-0 top-0 bottom-0 bg-gradient-to-l from-gray-eb to-transparent'></div>
                <Img src={`https://d2jgpj59n9tcev.cloudfront.net/assets/homepage/homepage-find-dream-apartment-desktop.jpg`} className='lg:flex object-contain object-left hidden w-full rounded-xl' style={{ height: 280 }} />
            </div>
            <div className='relative w-full flex lg:hidden' style={{ aspectRatio: '3.5/5.5' }}>
                <div className='absolute top-0 left-0 right-0 bottom-auto  z-1 bg-gradient-to-b from-white to-transparent w-full h-200px'></div>
                <div className='relative w-full h-full'>
                    <Img src={`https://d2jgpj59n9tcev.cloudfront.net/assets/homepage/homepage-find-dream-apartment-mobile.jpg`} className='flex lg:hidden w-full object-cover origin-bottom transform -translate-y-5 -translate-x-8 scale-125' />
                </div>
            </div>
            <div className='w-full z-5 lg:w-5/12 absolute top-0 pt-10 lg:pt-0 lg:right-0 lg:left-auto lg:bottom-0 justify-center flex flex-col items-center lg:items-start px-6 lg:px-0 lg:pr-20'>
                <h4 className='capitalize font-bold text-xl lg:text-2xl mb-5 text-center lg:text-left'>{lang.homepage_find_dream_apartment_title}</h4>
                <p className='mb-6 text-#727272 text-sm text-center lg:text-left'>
                    {lang.homepage_find_dream_apartment_subtitle}
                </p>
                <Button classes='flex flex-row bg-main items-center rounded-full py-3 px-6' onClick={onClickSearch}>
                    <SearchIconThin className='mr-2' />
                    <span className='text-base'>
                        {lang.find_apartment}
                    </span>
                </Button>
            </div>
        </div>
    )
}

export default FindYourDreamApartment