import { ReactNode } from "react"

interface IProps {
    children: ReactNode
    fontSize?: string
    fontWeight?: string
    fontColor?: string
    variant?: 'outlined' | 'contained' | 'plain'
    size?: 'sm' | 'md' | 'lg'
    color?: 'main' | 'accent' | 'white' | 'gray'
    classes?: string
    styles?: Object
    onClick?: Function
    fullWidth?: boolean
    borderSize?: string
    borderColor?: string
    href?: string
    target?: string
    disabled?: boolean
    rounded?: string
    plainClasses?: string
    testId?: string
    customPadding?: string
}

const Button = ({ children, fontSize, fontWeight, fontColor, variant, size, color, classes, styles, onClick, fullWidth, borderSize, borderColor, href, target, disabled, rounded, plainClasses, testId, customPadding }: IProps) => {
    const colorStyle = () => {
        if (variant && variant === 'outlined') {
            return `${borderSize || 'border'} ${borderColor}`
        } else if (variant && variant === 'contained') {
            switch (color) {
                case 'main':
                    return 'bg-main text-white md:hover:bg-main-dark active:bg-main-dark'
                case 'accent':
                    return 'bg-accent rounded text-white'
                case 'white':
                    return 'bg-white rounded text-black-48'
                case 'gray':
                    return 'bg-gray-200 rounded text-main'
                default:
                    return 'bg-main text-white md:hover:bg-main-dark active:bg-main-dark'
            }
        }
    }

    const baseStyle = (variant !== 'plain') ? `${rounded || 'rounded'} focus:shadow-outline` : plainClasses || 'md:hover:text-main active:text-main'

    const sizeStyle = () => {
        switch (size) {
            case 'sm':
                return ''
            case 'md':
                return 'py-1 px-3'
            case 'lg':
                return 'py-2 px-4 lg:px-5'
            default:
                return ''
        }
    }

    return (
        <>
            {
                href ?
                    <a
                        href={href}
                        target={target || undefined}
                        className={`${fullWidth ? 'w-full ' : ''}${baseStyle} ${customPadding || sizeStyle()} ${colorStyle()} ${classes} ${fontColor || 'text-white'} ${fontSize || 'text-base'} ${fontWeight || 'font-normal'} focus:outline-none`}
                        style={styles}
                        data-testid={testId || undefined}
                    >
                        {children}
                    </a>
                    :
                    <button
                        className={`${fullWidth ? 'w-full ' : ''}${baseStyle} ${customPadding || sizeStyle()} ${colorStyle()} ${classes} ${fontColor || 'text-white'} ${fontSize || 'text-base'} ${fontWeight || 'font-normal'} ${disabled ? 'cursor-not-allowed bg-gray-dd' : ''} focus:outline-none`}
                        onClick={() => onClick ? onClick() : undefined}
                        style={styles}
                        disabled={disabled}
                        data-testid={testId || undefined}
                        name="button"
                    >
                        {children}
                    </button>
            }
        </>
    )
}

export default Button